import React from 'react'
import { graphql } from 'gatsby'
import { mapEdgesToNodes } from '../lib/helpers'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import Layout from '../containers/layout'
import PortableText from '../components/portableText'
import { AiFillLinkedin, AiOutlineTwitter, AiFillGithub } from 'react-icons/ai'
// import '../styles/content.css'
import TitleAndDescription from '../components/TitleAndDescription'

export const query = graphql`
  query aboutPageQuery {
    gulcan: sanityAbout {
      _rawBio
      linkedIn
      name
      twitter
    }
  }
`

const AboutPage = props => {
  const { data, errors } = props
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }
  // const site = (data || {}).site

  // if (!site) {
  //   throw new Error(
  //     'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
  //   )
  // }

  return (
    <Layout>
      {/* <SEO title={site.title} description={site.description} keywords={site.keywords} /> */}
      <Container>
        <TitleAndDescription title='' excerpt={data.gulcan._rawBio} />

        <div class="bg-white">
          <div class="max-w-7xl mx-auto py-0 px-0 sm:px-0 lg:py-0 lg:px-0">
            <form class="mt-8 sm:flex" action="https://tinyletter.com/gulcanyayla" method="post" target="popupwindow" onsubmit="window.open('https://tinyletter.com/gulcanyayla', 'popupwindow', 'scrollbars=yes,width=800,height=600');return true">
              <label for="tlemail" class="sr-only">Email address</label>
                <input id="emailAddress" name="email" id="tlemail" type="email" autocomplete="email" required class="w-full px-5 py-3 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs border-gray-300 rounded-md" placeholder="Enter your email" />
                <input type="hidden" value="1" name="embed" />
              <div class="mt-3 rounded-md shadow sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                <button type="submit" class="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  Notify me
                </button>
              </div>
                        </form>
              <p class="mt-3 text-sm text-gray-500">
              Yazılardan haber almak için email listeme üye olabilirsiniz. 
              </p>
          </div>
        </div>

        <div className='mt-5'>
          <a href={data.gulcan.linkedIn} target='_blank'>
            <AiFillLinkedin className='cursor-pointer text-gray-500 hover:text-gray-400 text-2xl inline-block mr-3' />
          </a>
          <a href={data.gulcan.twitter} target='_blank'>
            <AiOutlineTwitter className='cursor-pointer text-gray-500 hover:text-gray-400 text-2xl inline-block mr-3' />
          </a>
          {/* <a href={data.gulcan.github} target='_blank'>
            <AiFillGithub className='cursor-pointer text-gray-500 hover:text-gray-400 text-2xl inline-block mr-3' />
          </a> */}
        </div>

      </Container>
    </Layout>
  )
}

export default AboutPage
